import React from 'react';
import AccessKeyPrompt from './components/AccessKeyPrompt';
import TTSInput from "./components/TTSInput";

function App() {
  return (
    <div className="App">
      <AccessKeyPrompt />
      <TTSInput />
    </div>
  );
}

export default App;
