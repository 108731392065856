import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import './AccessKeyPrompt.css';
import config from '../config';

function AccessKeyPrompt() {
  const [accessKey, setAccessKey] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check if the access key is already stored in cookies
    const storedKey = Cookies.get('__k9x_s3c_h4sh');
    if (!storedKey) {
      // If not found, show the popup
      setShowPopup(true);
    }
  }, []);

  const handleKeySubmit = async () => {
    // call the API to check if the key is valid
    const apiUrl = config.apiUrl;
    const response = await fetch(`${apiUrl}/check-key?key=${accessKey}`);
    const data = await response.json();
    if (data.isValid) {
      // Store the access key in cookies with obfuscated name
      Cookies.set('__k9x_s3c_h4sh', accessKey, { expires: 7 }); // Expires in 7 days
      setShowPopup(false);
    } else {
      alert('Invalid access key');
    }
  };

  if (!showPopup) {
    return null; // Don't render anything if the popup is not needed
  }

  return (
    <div className="overlay">
      <div className="popup">
        <p>액세스 키를 입력하세요</p>
        <input
          type="text"
          value={accessKey}
          onChange={(e) => setAccessKey(e.target.value)}
        />
        <button onClick={handleKeySubmit}>확인</button>
      </div>
    </div>
  );
}

export default AccessKeyPrompt;
